body {
  font-family: "Montserrat", sans-serif; }

.main {
  min-height: calc(100vh - 450px);
  padding-top: 95px;
  position: relative; }
  .main::before {
    position: absolute;
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-image: linear-gradient(to bottom, #e2f3f7, #ffffff);
    top: 0;
    left: 0;
    content: ''; }
  .main.covered {
    padding-top: 0; }
    .main.covered::before {
      content: none; }
  .main__header {
    height: 240px;
    background: #2C557E;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 80px; }
    .main__header--title {
      width: 100%;
      background-color: rgba(44, 85, 126, 0.9);
      padding: 30px;
      font-size: 24px;
      font-weight: 900;
      text-align: center;
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0; }

@media only screen and (max-width: 47.99em) {
  .sidebar {
    margin-top: 30px; } }

.sidebar__item--container {
  margin-top: 30px; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1110px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1110px; } }

.section--title {
  color: #317293;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 0; }

.sidebar--title {
  color: #317293;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0; }

.bds-pagination .pagination {
  margin: 0; }
  .bds-pagination .pagination .page-item {
    margin-right: 10px; }
    .bds-pagination .pagination .page-item.active .page-link {
      background-color: #2C557E;
      color: #fff;
      border-radius: 50%; }
    .bds-pagination .pagination .page-item.disable .page-link {
      opacity: .5; }
  .bds-pagination .pagination .page-link {
    font-size: 14px;
    font-family: "Fira Sans", sans-serif;
    color: #2C557E;
    background-color: #fff;
    padding: 0;
    height: 30px;
    width: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none; }

.button-white {
  background-color: #fff;
  color: #FF9100;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 45px;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 54, 109, 0.3); }
  .button-white:hover {
    text-decoration: none;
    background-color: #FF9100;
    color: #fff; }

.button-dark {
  background-color: #317293;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 45px;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 54, 109, 0.3);
  border: 1px solid #317293; }
  .button-dark:hover {
    text-decoration: none;
    background-color: #fff;
    color: #317293; }

.bds-button {
  background-color: #317293;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 8px 45px 8px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 54, 109, 0.3);
  border: 1px solid #317293; }
  .bds-button i {
    margin-right: 24px; }
  .bds-button:hover {
    text-decoration: none;
    background-color: #fff;
    color: #317293; }

.bds-form .form-group {
  margin-bottom: 20px; }

.bds-form label {
  font-size: 16px;
  color: #317293;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 10px; }

.bds-form .form-control {
  border-color: #3FC0CB;
  color: #333333 !important;
  font-family: "Fira Sans", sans-serif;
  background-color: #fff !important; }
  .bds-form .form-control::placeholder {
    color: #ccc !important; }
  .bds-form .form-control.iconed {
    padding-right: 40px; }

.bds-form select:required:invalid {
  color: #ccc !important; }

.bds-form option[value=""][disabled] {
  display: none; }

.bds-form option {
  color: #333333 !important; }

.bds-form--input {
  position: relative; }
  .bds-form--input i {
    position: absolute;
    top: 50%;
    right: 10px;
    color: #ccc;
    transform: translateY(-50%); }

.datepicker.dropdown-menu {
  font-size: .85rem;
  font-weight: normal; }

.datepicker table tr td.active, .datepicker table tr td.active.active {
  background: #317293 !important; }

.news__item:hover .news__item--title {
  text-decoration: none;
  color: #808080; }

.news__item:hover .news__item--image {
  opacity: .6; }

.news__item.big .news__item--image {
  height: 306px; }

.news__item.small {
  margin-bottom: 30px; }
  .news__item.small--title {
    text-decoration: none;
    color: #333333;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0; }

.news__item--date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Fira Sans", sans-serif;
  color: #317293;
  font-size: 14px;
  margin-bottom: 14px; }
  .news__item--date i {
    margin-right: 5px; }
  .news__item--date:hover {
    text-decoration: none;
    color: #317293; }

.news__item--image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px; }

.news__item--title {
  text-decoration: none;
  color: #333333;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 12.5px; }
  .news__item--title:hover {
    text-decoration: none;
    color: #808080; }

.news__item--text {
  font-family: "Fira Sans", sans-serif;
  color: #808080;
  font-size: 14px;
  text-decoration: none; }
  .news__item--text:hover {
    text-decoration: none;
    color: #808080; }

.singlenews__item--date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Fira Sans", sans-serif;
  color: #317293;
  font-size: 14px;
  margin-bottom: 24px; }
  .singlenews__item--date i {
    margin-right: 5px; }

.singlenews__item--image {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px; }

.singlenews__item--title {
  color: #333333;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: bold; }

.singlenews__item--text {
  font-family: "Fira Sans", sans-serif;
  color: #808080;
  font-size: 16px; }

.singlenews__hash--item img {
  height: 60px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px; }

.singlenews__carousel--item img {
  width: 100%;
  object-fit: cover;
  height: 430px;
  border-radius: 5px; }

.singlenews__carousel--nav {
  width: 100%;
  height: 100%; }
  .singlenews__carousel--nav .owl-prev {
    background: white;
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    color: #317293;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    left: 10px;
    transform: translateY(-50%); }
    .singlenews__carousel--nav .owl-prev:hover {
      background: rgba(255, 255, 255, 0.8); }
  .singlenews__carousel--nav .owl-next {
    background: white;
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    color: #317293;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 10px;
    transform: translateY(-50%); }
    .singlenews__carousel--nav .owl-next:hover {
      background: rgba(255, 255, 255, 0.8); }

.footer {
  margin-top: 100px; }
  .footer-top {
    padding-bottom: 20px; }
  .footer-bottom {
    padding: 30px 0;
    background-color: #f3fdff; }
  .footer__socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px; }
    @media only screen and (min-width: 48em) {
      .footer__socials {
        margin-bottom: 0; } }
    .footer__socials--link {
      opacity: 1;
      display: inline-block;
      line-height: 1; }
      .footer__socials--link img {
        height: 20px;
        object-fit: contain; }
      .footer__socials--link:not(:last-child) {
        margin-right: 10px; }
      .footer__socials--link:hover {
        opacity: .7; }
  .footer__subscribe {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .footer__subscribe--label {
      font-size: 14px;
      color: #333333;
      margin: 0;
      margin-right: 15px;
      font-family: "Fira Sans", sans-serif;
      font-weight: normal !important; }
    .footer__subscribe--input {
      position: relative;
      width: 255px; }
      .footer__subscribe--input .form-control {
        height: 40px;
        border: solid 1px #3FC0CB;
        border-radius: 20px;
        font-weight: normal;
        color: #333333 !important;
        font-size: 13px !important;
        font-family: "Fira Sans", sans-serif; }
        .footer__subscribe--input .form-control::placeholder {
          color: #999999;
          font-size: 13px !important;
          font-family: "Fira Sans", sans-serif;
          font-weight: normal; }
    .footer__subscribe--icon {
      border: none;
      box-shadow: none;
      background: none !important;
      outline: none;
      line-height: 0;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #2C557E;
      height: 100%;
      border-left: 1px solid #3FC0CB; }
      .footer__subscribe--icon:hover {
        color: #3FC0CB; }
  .footer--logo {
    max-width: 100%;
    object-fit: contain; }
  @media only screen and (max-width: 47.99em) {
    .footer__menu {
      border-top: 1px solid #CFEDF3;
      padding-top: 30px;
      margin-top: 30px; } }
  .footer__menu--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2C557E;
    font-size: 14px;
    margin-bottom: 10px; }
    .footer__menu--link i {
      margin-right: 5px; }
    .footer__menu--link:hover {
      text-decoration: none;
      color: #3FC0CB; }
  .footer__address--title {
    color: #2C557E;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 5px; }
  .footer__address--copyright {
    font-size: 14px;
    color: #2C557E;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 10px; }
  .footer__address--item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #2C557E;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 10px; }
    .footer__address--item i {
      margin-right: 5px;
      font-size: 16px; }
    .footer__address--item a {
      color: #2C557E;
      font-family: "Fira Sans", sans-serif;
      font-size: 14px;
      text-decoration: none; }

.bds-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  padding: 0;
  color: #317293;
  opacity: 1; }

.bds-modal .modal-dialog {
  max-width: 540px; }

.booknow {
  width: 100%;
  padding: 40px; }
  .booknow--text {
    margin: 20px 0 0 0;
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    color: #333333; }
  .booknow__form {
    margin-top: 50px; }

@media only screen and (max-width: 61.99em) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: #fff; } }

.header-top {
  background-color: #2C557E;
  width: calc(100% - 60px);
  margin: 0 auto;
  height: 30px;
  padding: 5px 0;
  display: none;
  align-items: center;
  justify-content: flex-start; }
  @media only screen and (min-width: 62em) {
    .header-top {
      display: flex; } }
  .header-top::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("../img/header-left.svg");
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px; }
  .header-top::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("../img/header-right.svg");
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px; }

.header-bottom {
  background-color: #fff;
  padding: 20px;
  display: none; }
  @media only screen and (min-width: 62em) {
    .header-bottom {
      display: flex; } }

.header__socials {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .header__socials--link {
    opacity: 1;
    display: inline-block;
    line-height: 1; }
    .header__socials--link img {
      height: 20px;
      object-fit: contain; }
    .header__socials--link:not(:last-child) {
      margin-right: 10px; }
    .header__socials--link:hover {
      opacity: .7; }

.header__search {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .header__search--label {
    font-size: 14px;
    color: #808080;
    margin: 0;
    margin-right: 15px;
    font-family: "Fira Sans", sans-serif; }
  .header__search--input {
    position: relative;
    width: 255px; }
    .header__search--input .form-control {
      height: 40px;
      border: solid 1px #3FC0CB;
      border-radius: 20px;
      color: #333333;
      font-size: 14px;
      font-family: "Fira Sans", sans-serif; }
      .header__search--input .form-control::placeholder {
        color: #333333;
        font-size: 14px;
        font-family: "Fira Sans", sans-serif; }
  .header__search--icon {
    border: none;
    box-shadow: none;
    background: none !important;
    outline: none;
    line-height: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: #2C557E; }
    .header__search--icon:hover {
      color: #3FC0CB; }

.logo {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; }
  .logo.search {
    left: 50px; }
  .logo img {
    width: 90px;
    object-fit: contain; }
  @media only screen and (min-width: 62em) {
    .logo {
      top: -5px; }
      .logo img {
        width: 130px;
        object-fit: contain; } }

.language {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .language--icon {
    font-size: 17px;
    color: #9adae3;
    margin-right: 8px;
    line-height: 0; }
  .language--link {
    font-size: 14px;
    color: #fff; }
    .language--link:hover, .language--link.active {
      color: #3FC0CB;
      text-decoration: none; }
    .language--link:not(:last-child) {
      margin-right: 8px; }

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .menu--link {
    color: #2C557E;
    font-size: 13px; }
    .menu--link:hover, .menu--link.active {
      color: #3FC0CB;
      text-decoration: none; }
    .menu--link:not(:last-child) {
      margin-right: 12px; }

.mobile__header {
  border-top: 10px solid #2C557E;
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 62em) {
    .mobile__header {
      display: none; } }
  .mobile__header--menu {
    font-size: 32px;
    color: #2C557E; }
  .mobile__header--search {
    font-size: 32px;
    color: #2C557E; }

.mobile__menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 99;
  overflow: auto;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;
  display: none; }
  .mobile__menu.active {
    display: flex; }
  .mobile__menu--footer {
    background-color: #2C557E;
    padding: 30px 0; }
  .mobile__menu--close {
    display: inline-block;
    font-size: 32px;
    color: #2C557E;
    margin-top: 20px; }

.mobile__nav {
  margin: 50px 0; }
  .mobile__nav--link {
    color: #2C557E;
    display: block;
    font-size: 16px; }
    .mobile__nav--link:not(:last-child) {
      margin-bottom: 20px; }
    .mobile__nav--link:hover, .mobile__nav--link.active {
      color: #3FC0CB;
      text-decoration: none; }

.mobile__language {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px; }
  .mobile__language--icon {
    font-size: 17px;
    color: #9adae3;
    margin-right: 15px;
    line-height: 0; }
  .mobile__language--link {
    font-size: 14px;
    color: #fff; }
    .mobile__language--link:hover, .mobile__language--link.active {
      color: #3FC0CB;
      text-decoration: none; }
    .mobile__language--link:not(:last-child) {
      margin-right: 20px; }

.mobile__social {
  display: flex;
  align-items: center;
  justify-content: center; }
  .mobile__social--link {
    opacity: 1;
    display: inline-block;
    line-height: 1; }
    .mobile__social--link img {
      height: 30px;
      object-fit: contain; }
    .mobile__social--link:not(:last-child) {
      margin-right: 25px; }
    .mobile__social--link:hover {
      opacity: .7; }

.mobile__search {
  width: calc(100% - 160px);
  position: absolute;
  left: 100px;
  display: none; }
  .mobile__search.active {
    display: block; }
  .mobile__search input {
    width: calc(100%);
    border: none;
    color: #333333;
    font-size: 14px;
    font-family: "Fira Sans", sans-serif; }
    .mobile__search input::placeholder {
      color: #808080; }

.menu-in {
  animation: menu-in .5s ease-in-out; }

@keyframes menu-in {
  0% {
    height: 0;
    opacity: 0; }
  100% {
    opacity: 1;
    height: 100vh; } }

.menu-out {
  animation: menu-out .5s ease-in-out; }

@keyframes menu-out {
  0% {
    opacity: 1;
    height: 100vh; }
  100% {
    height: 0;
    opacity: 0; } }

.courses__item {
  padding: 30px;
  background: #f3fdff;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .courses__item--icon {
    width: 60px;
    margin-right: 40px; }
    .courses__item--icon img {
      width: 100%;
      object-fit: contain; }
  .courses__item--content {
    width: calc(100% - 100px); }
  .courses__item--title {
    color: #2C557E;
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 20px; }
  .courses__item--text {
    font-size: 14px;
    font-family: "Fira Sans", sans-serif;
    color: #333333;
    margin-bottom: 10px;
    min-height: 65px; }
  .courses__item--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #3FC0CB;
    margin-top: 12px; }
    .courses__item--link i {
      margin-right: 5px; }
    .courses__item--link:hover {
      text-decoration: none;
      color: #317293; }

.course--title {
  font-size: 24px;
  color: #2C557E;
  font-weight: 900;
  margin-bottom: 20px; }

.course--date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #3FC0CB;
  margin-bottom: 40px; }
  .course--date i {
    margin-right: 10px; }

.course--text {
  font-size: 16px;
  color: #333333;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 60px; }
  .course--text p {
    margin: 0; }
    .course--text p:not(:last-child) {
      margin-bottom: 15px; }

.course--header {
  font-size: 18px;
  font-weight: 900;
  color: #333333;
  margin-bottom: 30px; }

.course__syllabus {
  margin-bottom: 60px; }
  .course__syllabus--list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    color: #333333; }
    .course__syllabus--list li:not(:last-child) {
      margin-bottom: 20px; }
    .course__syllabus--list > li:before {
      content: "\2014";
      padding-right: 10px; }

.course__calendar--item {
  padding: 20px;
  background: #f3fdff;
  border-radius: 5px;
  margin-bottom: 20px; }

.course__calendar--content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px; }

.course__calendar--start, .course__calendar--end {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.course__calendar--link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #3FC0CB; }
  .course__calendar--link i {
    margin-right: 5px; }
  .course__calendar--link:hover {
    text-decoration: none;
    color: #317293; }

.course__calendar--day {
  font-size: 32px;
  color: #2C557E;
  margin-right: 10px; }

.course__calendar--date {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 12px;
  font-family: "Fira Sans", sans-serif;
  color: #2C557E; }

.course__calendar--dash {
  display: inline-block;
  width: 32px;
  height: 4px;
  background-color: #2C557E;
  margin: 0 10px; }

.course__sidebar {
  background-color: #2C557E;
  color: #fff;
  border-radius: 5px; }
  .course__sidebar .course--header {
    padding: 20px;
    color: #fff; }

.course__nav--link {
  padding: 10px 20px;
  padding-left: 45px;
  display: flex;
  width: 100%;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px; }
  .course__nav--link:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .course__nav--link:hover {
    color: #fff;
    text-decoration: none; }
  .course__nav--link.active {
    font-weight: bold;
    padding-left: 20px;
    color: #fff; }

.contact__form {
  background-color: #f3fdff;
  padding: 30px;
  border-radius: 5px;
  margin-top: 30px; }
  @media only screen and (max-width: 47.99em) {
    .contact__form {
      margin-bottom: 30px; } }
  .contact__form--text {
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 30px; }

.contact__map {
  margin-top: 30px;
  overflow: hidden; }
  .contact__map iframe {
    border-radius: 5px; }
  .contact__map--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #317293;
    margin-top: 12px; }
    .contact__map--link i {
      margin-right: 5px; }
    .contact__map--link:hover {
      text-decoration: none;
      color: #3FC0CB; }

.contact__details {
  margin-top: 35px; }
  .contact__details--item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px; }
    .contact__details--item i {
      color: #3FC0CB;
      margin-right: 10px; }
    .contact__details--item p {
      margin: 0;
      font-family: "Fira Sans", sans-serif;
      color: #333333;
      font-size: 16px; }
    .contact__details--item a {
      margin: 0;
      font-family: "Fira Sans", sans-serif;
      color: #333333;
      font-size: 16px; }

.contact__socials {
  margin-top: 40px; }
  .contact__socials--title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
    color: #333333; }
  .contact__socials--link {
    opacity: 1;
    display: inline-block;
    line-height: 1; }
    .contact__socials--link img {
      height: 40px;
      object-fit: contain; }
    .contact__socials--link:not(:last-child) {
      margin-right: 10px; }
    .contact__socials--link:hover {
      opacity: .7; }

.error-page {
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: stretch; }
  .error-page--image {
    height: 160px;
    object-fit: contain;
    margin-bottom: 30px; }
  .error-page--title {
    font-size: 24px;
    color: #2C557E;
    font-weight: 900;
    margin-bottom: 10px; }
  .error-page--text {
    color: #333333;
    font-size: 14px;
    font-family: "Fira Sans", sans-serif; }

.home-slider {
  width: 100%; }

.slider {
  width: 100%;
  position: relative; }
  .slider__item {
    position: relative; }
    .slider__item--image {
      width: 100%;
      height: 430px;
      object-fit: cover; }
    .slider__item--content {
      position: absolute;
      width: 100%;
      padding: 30px 0;
      background-color: #2c557e;
      bottom: 0;
      left: 0;
      text-align: center;
      color: #fff; }
      @media only screen and (min-width: 62em) {
        .slider__item--content {
          background-color: rgba(44, 85, 126, 0.5); } }
      .slider__item--content h4 {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 10px; }
      .slider__item--content p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0; }
  .slider__nav {
    width: 100%; }
    .slider__nav .owl-prev {
      background: #fff;
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      color: #2C557E;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      left: 20px; }
    .slider__nav .owl-next {
      background: #fff;
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      color: #2C557E;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 20px; }
  .slider__dots {
    margin-top: 15px;
    text-align: center;
    width: 100%;
    line-height: 0; }
    .slider__dots .owl-dot {
      outline: none;
      box-shadow: none;
      border: none;
      line-height: 1;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #317293 !important; }
      .slider__dots .owl-dot.active {
        background-color: #FF9100 !important;
        width: 8px;
        height: 8px; }
      .slider__dots .owl-dot:not(:last-child) {
        margin-right: 10px; }

.sharp {
  margin-top: -25px;
  position: relative;
  width: 100%;
  min-height: 400px;
  background-image: url("../img/sharp-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .sharp--image {
    width: 100%;
    object-fit: cover; }
  .sharp__content {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 100px;
    text-align: center; }
    .sharp__content p {
      color: #2C557E;
      font-size: 20px;
      margin: 0; }
    .sharp__content img {
      margin-top: 50px;
      max-width: 100%;
      object-fit: contain; }

.offer {
  padding: 15px 0 70px 0;
  background-color: #fff; }
  .offer__list {
    margin-top: 45px; }
    .offer__list--item {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .offer__list--item-icon {
        margin-right: 10px;
        color: #FF9100;
        font-size: 24px; }
      .offer__list--item-title {
        font-size: 15px;
        color: #333333;
        font-family: #FF9100; }

.testimonials {
  width: 100%;
  min-height: 600px;
  background-image: url("../img/testimonials.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .testimonials__slider--image {
    height: 120px !important;
    width: 120px !important;
    object-fit: cover;
    border-radius: 50%;
    border: 10px solid #fff; }
    @media only screen and (min-width: 48em) {
      .testimonials__slider--image {
        width: 200px !important;
        height: 200px !important; } }
    @media only screen and (min-width: 75em) {
      .testimonials__slider--image {
        width: 255px !important;
        height: 255px !important; } }
  .testimonials__slider--content {
    margin-top: 20px; }
    @media only screen and (min-width: 48em) {
      .testimonials__slider--content {
        margin-top: 55px; } }
  .testimonials__slider--name {
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
    display: block;
    font-family: "Fira Sans", sans-serif; }
  .testimonials__slider--title {
    font-size: 28px;
    color: #fff;
    margin-bottom: 12.5px;
    font-weight: 900; }
  .testimonials__slider--text {
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
    display: block;
    font-family: "Fira Sans", sans-serif; }
  .testimonials__slider--nav {
    width: 100%;
    height: 100%; }
    .testimonials__slider--nav .owl-prev {
      background: white;
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: -40px;
      width: 40px;
      height: 40px;
      color: #317293;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      left: 0px; }
      @media only screen and (min-width: 48em) {
        .testimonials__slider--nav .owl-prev {
          top: 0; } }
      .testimonials__slider--nav .owl-prev:hover {
        background: rgba(255, 255, 255, 0.8); }
    .testimonials__slider--nav .owl-next {
      background: white;
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      bottom: 0;
      width: 40px;
      height: 40px;
      color: #317293;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 0px; }
      .testimonials__slider--nav .owl-next:hover {
        background: rgba(255, 255, 255, 0.8); }

.latestnews {
  padding: 70px 0 70px 0; }
  .latestnews--container {
    padding: 40px 0; }

.gallery {
  padding: 30px 0 0 0; }
  .gallery--container {
    padding: 30px 0; }
  .gallery__slider .owl-item:nth-child(2n) {
    margin-top: 10px; }
  .gallery__slider--item {
    margin-right: 10px;
    text-align: center; }
  .gallery__slider--image {
    width: 100%;
    object-fit: cover; }

.news-page--header {
  margin-bottom: 60px; }
  .news-page--header .news__item {
    margin-bottom: 50px; }

.news-page--footer .news__item {
  margin-bottom: 50px; }

.search__button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px; }
  .search__button--input {
    position: relative;
    width: 100%; }
    .search__button--input .form-control {
      height: 40px;
      border: solid 1px #3FC0CB;
      border-radius: 20px;
      color: #333333;
      font-size: 14px;
      font-family: "Fira Sans", sans-serif; }
      .search__button--input .form-control::placeholder {
        color: #333333;
        font-size: 14px;
        font-family: "Fira Sans", sans-serif; }
  .search__button--icon {
    border: none;
    box-shadow: none;
    background: none !important;
    outline: none;
    line-height: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: #2C557E; }
    .search__button--icon:hover {
      color: #3FC0CB; }

.search__results--count {
  margin-top: 20px;
  margin-bottom: 40px;
  color: #808080;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif; }

.search__results--empty {
  font-size: 24px;
  font-family: "Fira Sans", sans-serif;
  color: #999999;
  margin: 0; }

.search__result {
  padding: 20px 30px 30px 30px;
  background-color: #f3fdff;
  border-radius: 5px;
  margin-bottom: 10px; }
  .search__result--category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: "Fira Sans", sans-serif;
    color: #2C557E;
    margin-bottom: 20px; }
    .search__result--category i {
      font-size: 14px;
      margin-right: 5px; }
  .search__result--title {
    display: block;
    color: #333333;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px; }
  .search__result--text {
    color: #333333;
    font-size: 14px;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 20px; }
  .search__result--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #3FC0CB; }
    .search__result--link i {
      margin-right: 5px; }
    .search__result--link:hover {
      text-decoration: none;
      color: #317293; }

.team--container .card {
  border: none;
  margin: 0; }

.team--container .card-columns {
  column-count: 1;
  column-gap: 30px; }
  @media only screen and (min-width: 48em) {
    .team--container .card-columns {
      column-count: 2;
      column-gap: 30px; } }
  @media only screen and (min-width: 62em) {
    .team--container .card-columns {
      column-count: 3;
      column-gap: 30px; } }

.team__member {
  background-color: #f3fdff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px; }
  .team__member--image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    display: block; }
  .team__member--name {
    font-size: 18px;
    font-weight: 900;
    color: #333333;
    margin: 10px 0; }
  .team__member--title {
    font-size: 14px;
    color: #999999;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 10px;
    display: block; }
  .team__member--text {
    font-size: 14px;
    color: #333333;
    font-family: "Fira Sans", sans-serif;
    margin: 0;
    display: block; }

.about__text {
  color: #333333;
  font-family: "Fira Sans", sans-serif;
  margin-bottom: 60px; }
  .about__text p {
    font-size: 16px;
    margin-bottom: 20px; }

.about__info {
  border-radius: 5px;
  background-color: #f3fdff;
  padding: 30px;
  height: calc(100% - 30px);
  margin-bottom: 30px; }
  .about__info--title {
    font-size: 24px;
    color: #2C557E;
    font-weight: bold;
    margin-bottom: 20px; }
  .about__info--text {
    color: #2C557E;
    font-size: 16px;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0; }

.about__sharp {
  margin-top: 70px; }
  .about__sharp--text {
    text-align: center;
    color: #2C557E;
    font-size: 20px;
    margin: 0; }
  .about__sharp--full {
    width: 100%;
    min-height: 600px;
    background-image: url("../img/bg-about.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .about__sharp--img {
    max-width: 100%;
    object-fit: contain; }

.about__accreditations {
  margin-top: 100px;
  text-align: center; }
  .about__accreditations--text {
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    color: #333333;
    margin: 40px 0;
    text-align: left; }
  .about__accreditations--img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px 30px;
    background-color: #fff;
    border: 1px solid #707070;
    margin-bottom: 30px; }
    .about__accreditations--img img {
      height: 77px;
      object-fit: contain; }

.about__customers {
  margin-top: 70px; }
  .about__customers--slider {
    margin-top: 40px; }
  .about__customers--nav {
    width: 100%; }
    .about__customers--nav .owl-prev {
      background: rgba(255, 255, 255, 0.3);
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      left: 20px; }
    .about__customers--nav .owl-next {
      background: rgba(255, 255, 255, 0.3);
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 20px; }
  .about__customers--dots {
    margin-top: 15px;
    text-align: center;
    width: 100%;
    line-height: 0; }
    .about__customers--dots .owl-dot {
      outline: none;
      box-shadow: none;
      border: none;
      line-height: 1;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #317293 !important; }
      .about__customers--dots .owl-dot.active {
        background-color: #FF9100 !important;
        width: 8px;
        height: 8px; }
      .about__customers--dots .owl-dot:not(:last-child) {
        margin-right: 10px; }

.about__customer {
  width: 100%;
  background-image: linear-gradient(to right bottom, rgba(63, 192, 203, 0.58), rgba(44, 85, 126, 0.67));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  color: #fff; }
  .about__customer--logo {
    height: 80px;
    object-fit: contain; }
    @media only screen and (min-width: 48em) {
      .about__customer--logo {
        height: 160px; } }
  .about__customer--text {
    margin: 30px 0 20px;
    font-size: 16px;
    padding: 0 20px;
    line-height: 1;
    text-align: center; }
    @media only screen and (min-width: 48em) {
      .about__customer--text {
        padding: 0 50px;
        font-size: 18px; } }
    @media only screen and (min-width: 62em) {
      .about__customer--text {
        padding: 0 100px;
        font-size: 24px; } }
  .about__customer--author {
    font-size: 12px;
    font-family: "Fira Sans", sans-serif;
    display: block; }
    @media only screen and (min-width: 62em) {
      .about__customer--author {
        font-size: 14px; } }
